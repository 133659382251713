import React from 'react'
import UsersPage from '../components/UsersPage'
import Layout from '../components/Layout/Layout'

function Users(props) {
  return (
    <Layout {...props}>
      <UsersPage {...props} />
    </Layout>
  )
}
export default Users
