import React, { useEffect } from 'react'
import { withWidth } from '@material-ui/core'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'
import { getCurrentUser } from '../../utils/auth'
import ListUsers from './ListUsers'

function UsersPage(props) {
  useEffect(() => {
    ;(async () => {
      if (await !isLoggedIn()) {
        navigate('/signin/')
      }
      if (await !getCurrentUser()['custom:isAdmin']) {
        navigate('/dashboard/')
      }
    })()
  }, [])

  return (
    <div>
      <ListUsers {...props}></ListUsers>
    </div>
  )
}

export default withWidth()(UsersPage)
