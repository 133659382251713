import React, { useState, useEffect } from 'react'
import { handleConnectionApiGet } from '../../utils/ApiConnect'
import { makeStyles } from '@material-ui/core/styles'
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons'
import { TableRow as TableUsers } from '../../Contents/TableRow'
import DetailUsers from './DetailUsers'

const columns = [
  { title: 'Email', field: 'email' },
  { title: 'Sitio web', field: 'website' },
  {
    title: 'Roles',
    field: 'custom:isAdmin',
    render: (rowData) =>
      rowData['custom:isAdmin'] === 'true' ? 'Administrador' : 'Normal',
  },
]

export default function ListUsers(props) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [componentDetail, setComponentDetail] = useState(null)
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
  const actions = [
    {
      icon: () => <MoreHorizIcon fontSize="large" />,
      tooltip: 'Editar usuario',
      onClick: (event, rowData) => handleDetailUser(rowData),
    },
  ]

  useEffect(() => {
    let abortController = new AbortController()

    if (lastEvaluatedKey !== undefined) {
      ;(async () => {
        await handleConnectionApiGet('/users', { ...lastEvaluatedKey })
          .then((response) => {
            const { items, LastEvaluatedKey } = response.Response
            setRows((state) => [...state, ...items])
            if (LastEvaluatedKey) {
              setLastEvaluatedKey(LastEvaluatedKey)
            }
            setLastEvaluatedKey(undefined)
          })
          .catch((error) => {
            console.log(error)
            setLastEvaluatedKey(undefined)
          })
      })()
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [lastEvaluatedKey])

  const handleDetailUser = (items) => {
    setComponentDetail(
      <DetailUsers
        callback={setComponentDetail}
        handleUpdateList={handleUpdateList}
        items={items}
        {...props}
      />
    )
  }

  const handleUpdateList = () => {
    setRows([])
    setLastEvaluatedKey(null)
  }

  return (
    <div className={classes.root}>
      <TableUsers
        title="Usuarios"
        columns={columns}
        data={rows}
        actions={actions}
      />
      {componentDetail}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))
