export const Licenses = [
  {
    value: '',
    label: 'licencias',
    disabled: true,
  },
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'premium',
    label: 'Premium',
  },
]

export const TypeUsers = [
  {
    value: 'true',
    label: 'Administrador',
  },
  {
    value: 'false',
    label: 'Normal',
  },
]
